import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "baseline-patching"
    }}>{`Baseline patching`}</h1>
    <hr></hr>
    <p><strong parentName="p">{`IMPORTANT`}</strong></p>
    <p>{`Currently the `}<inlineCode parentName="p">{`dry run`}</inlineCode>{` setting has no effect if baseline is enabled, hence it's improssible to create dry-run
plans inside a pipeline with baseline feature enabled.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "general-information"
    }}>{`General information`}</h2>
    <p>{`Baseline patching allows to install exactly the same vendor update patches on
many environments. The baseline is automatically built based on the patches that
were successfully installed on the first environment in the pipeline. All
installed patches from the first step are divided into groups based on OS type
and version that is retrieved from SSM metadata `}<inlineCode parentName="p">{`PlatformName`}</inlineCode>{` and
`}<inlineCode parentName="p">{`PlatformVersion`}</inlineCode>{` fields. For example Windows Server 2012 is treated as a
separate baseline source than Windows Server 2016. Patches are retrieved from
the corresponding group created in the first step. In later steps the decision
on what patches to install on a particular machine will be made based on that
machine’s OS type and version. Please note that we provide best effort solution
and if machines states differ between steps, patching can result in missing
packages, which will be reported.`}</p>
    <h2 {...{
      "id": "operating-systems"
    }}>{`Operating systems`}</h2>
    <h3 {...{
      "id": "supported"
    }}>{`Supported`}</h3>
    <ul>
      <li parentName="ul">{`Windows Server 2008 - 2019, including R2 versions`}</li>
      <li parentName="ul">{`Amazon Linux 2012.03 - 2018.03`}</li>
      <li parentName="ul">{`Amazon Linux 2`}</li>
      <li parentName="ul">{`CentOS 6.x - 8.x`}</li>
      <li parentName="ul">{`Red Hat Enterprise Linux (RHEL)`}</li>
      <li parentName="ul">{`SUSE Linux`}</li>
      <li parentName="ul">{`SUSE Linux Enterprise Server (SLES)`}</li>
      <li parentName="ul">{`Ubuntu Server 14.04 LTS and later`}</li>
    </ul>
    <h2 {...{
      "id": "setting-up"
    }}>{`Setting up`}</h2>
    <h3 {...{
      "id": "creating"
    }}>{`Creating`}</h3>
    <p>{`Baseline patching configuration is available on a pipeline level. Please note
that for baseline to work properly, machines with each patched OS type and
version must be included in the first step (i.e. if the second step contains
an `}<inlineCode parentName="p">{`Ubuntu 14.04`}</inlineCode>{` machine the first step `}<strong parentName="p">{`must`}</strong>{` also contain at least one
`}<inlineCode parentName="p">{`Ubuntu 14.04`}</inlineCode>{` machine).`}</p>
    <p>{`To create new baseline pipeline in
the User interface go from left navigation bar to `}<strong parentName="p">{`Plan pipelines`}</strong>{` > `}<strong parentName="p">{`New
pipeline`}</strong>{`. To change existing pipeline into baseline go to `}<strong parentName="p">{`Plan pipelines`}</strong>{`,
search for plan and click `}<strong parentName="p">{`Edit`}</strong>{`. Then, in both cases, switch the checkbox `}<strong parentName="p">{`Enable
baseline`}</strong>{` on. `}<img alt="creating" src={require("../assets/baseline/create.png")} /></p>
    <h3 {...{
      "id": "system-requirements"
    }}>{`System requirements`}</h3>
    <p>{`Each machine must have SSM agent installed and be registered to AutoPatcher.
This is the same process as in standard patching, which is described in `}<a parentName="p" {...{
        "href": "/setting-up-ssm"
      }}>{`Setting
up SSM agent`}</a>{` and in `}<a parentName="p" {...{
        "href": "/adding-machine"
      }}>{`Adding a machine to AutoPatcher`}</a>{`.
Please note that existing machines may require policy update to allow access to SSM baseline documents
(the `}<inlineCode parentName="p">{`arn:aws:ssm:*:*:document/AWS-RunPatchBaseline`}</inlineCode>{` and `}<inlineCode parentName="p">{`arn:aws:ssm:*:\${AutoPatcherAccountID}:document/AP-RunPatchBaseline`}</inlineCode>{`
resources should be allowed for `}<inlineCode parentName="p">{`ssm:SendCommand`}</inlineCode>{` action,
the details are `}<a parentName="p" {...{
        "href": "/setting-up-ssm#iam-service-role-for-autopatcher-to-operate"
      }}>{`here`}</a>{`).`}</p>
    <p>{`All Linux machines must have Python 2.7 or higher installed.`}</p>
    <p>{`CentOS machines must have the `}<inlineCode parentName="p">{`yum-utils`}</inlineCode>{` package installed and GPG key
imported. In CentOS 7 that can be done by following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`sudo rpm --import /etc/pki/rpm-gpg/RPM-GPG-KEY-CentOS-7
`}</code></pre>
    <p>{`More details about system requirements for the `}<inlineCode parentName="p">{`AWS-RunPatchBaseline`}</inlineCode>{` SSM document
(which is used for installing the packages in a baseline patching) are presented on the
`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/systems-manager/latest/userguide/patch-manager-about-aws-runpatchbaseline.html"
      }}>{`Official AWS SSM documentation page`}</a>{`.`}</p>
    <h2 {...{
      "id": "patching"
    }}>{`Patching`}</h2>
    <h3 {...{
      "id": "windows"
    }}>{`Windows`}</h3>
    <p>{`Baseline for Windows leverages the default patch baseline provided by AWS.
There, patches are installed when they have classification set to
`}<inlineCode parentName="p">{`CriticalUpdates`}</inlineCode>{` or `}<inlineCode parentName="p">{`SecurityUpdates`}</inlineCode>{` and they have severity set to `}<inlineCode parentName="p">{`Critical`}</inlineCode>{`
or `}<inlineCode parentName="p">{`Important`}</inlineCode>{`. After the first step, baseline builder collects all the
installed packages on machines from SSM. Exact statuses are: `}<inlineCode parentName="p">{`Installed`}</inlineCode>{`,
`}<inlineCode parentName="p">{`InstalledOther`}</inlineCode>{` and `}<inlineCode parentName="p">{`InstalledPendingReboot`}</inlineCode>{`. `}<inlineCode parentName="p">{`InstalledOther`}</inlineCode>{` indicates that
patch was installed by OS provider. `}<inlineCode parentName="p">{`InstalledPendingReboot`}</inlineCode>{` indicates that
installed patch requires reboot. After reboot, status is changed to `}<inlineCode parentName="p">{`Installed`}</inlineCode>{`.
When build process is completed, packages are passed as a `}<inlineCode parentName="p">{`InstallOverrideList`}</inlineCode>{`
argument for the next steps, so that following machines can only install updates
from provided packages.`}</p>
    <h3 {...{
      "id": "linux"
    }}>{`Linux`}</h3>
    <p>{`In Linux, AutoPatcher uses modified SSM baseline document, that allows to use
custom repository during update. This ensures that none of the installed
packages will be missing, when the next steps are executed. Custom repository is
built after the first step and contains all of the installed packages for
particular OS type and version, retrieved directly from corresponding package
manager. Nonetheless custom baseline document still leverages on SSM baseline
mechanism, so each distribution uses its own, default baseline. This means that
patches are filtered as following:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Ubuntu`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Product`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Section`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Priority`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Required,Important,Standard,Optional,Extra`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Compliance reporting`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Unspecified`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Include non-security updates`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`No`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Amazon Linux`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Product`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Classification`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Security`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Bugfix`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Severity`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Critical,Important`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Compliance reporting`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Unspecified`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Unspecified`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Include non-security updates`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`No`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`No`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`CentOS`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Product`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`*`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Classification`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Severity`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Compliance reporting`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Unspecified`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Include non-security updates`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`Yes`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "error-handling-and-manual-approval"
    }}>{`Error handling and manual approval`}</h2>
    <p>{`The step approval mechanism for second step in a pipeline with the baseline option enabled is different than that without that option (third and later steps behave the same as in usual pipelines). The following subsections describe the flow depending on the patching policies the second step can have.`}</p>
    <h3 {...{
      "id": "success-or-approval-default"
    }}>{`Success or approval (default)`}</h3>
    <p>{`If the first step fails, the baseline building process does not start automatically. It starts only after the user manually approves the second step. While baseline is building, the events from the second step are in the `}<inlineCode parentName="p">{`APPROVED`}</inlineCode>{` state and are changed to `}<inlineCode parentName="p">{`NEW`}</inlineCode>{` automatically when the baseline building process finishes successfully. If it fails (which in general is `}<strong parentName="p">{`not`}</strong>{` expected to happen) the events from the `}<strong parentName="p">{`first`}</strong>{` step are marked as `}<inlineCode parentName="p">{`DONE_ERROR_BASELINE`}</inlineCode>{` and the second step is changed back to `}<inlineCode parentName="p">{`NOT_APPROVED`}</inlineCode>{`. In this situation the user can make a decision to continue patching anyway (i.e. the baseline building failure was a false-positive or in other way acceptable to proceed) by approving the second step again (so called `}<em parentName="p">{`double approval`}</em>{`). Then the events from the first step are changed back to their original states and the second step is marked as `}<inlineCode parentName="p">{`NEW`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "patch-anyway"
    }}>{`Patch anyway`}</h3>
    <p>{`If the first step fails, the baseline building process is started anyway (similar to `}<em parentName="p">{`success or approval`}</em>{`, the second step events are temporarily marked as `}<inlineCode parentName="p">{`APPROVED`}</inlineCode>{` for that time). However, if the building fails, the first step marked id as `}<inlineCode parentName="p">{`DONE_ERROR_BASELINE`}</inlineCode>{` and the second as `}<inlineCode parentName="p">{`NOT_APPROVED`}</inlineCode>{` thus requiring manual approval from the user (similar to the `}<em parentName="p">{`double approve`}</em>{` mechanism described in `}<em parentName="p">{`success or approval`}</em>{` section).`}</p>
    <h3 {...{
      "id": "approval-required"
    }}>{`Approval required`}</h3>
    <p>{`In this case the baseline building starts only after the user manually approves the second step, regardless of the first step status. The baseline building process failure is handled in the same manner as for other policies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      